import React from 'react'
import paper_plane from "../../Assets/Common/paper-plane.webp"

import "./Footer.css"

export default function Footer({isPageNotFound}) {
  return (
    <footer 
      className="footer"
      // style={{
      //   marginTop: isPageNotFound ? "0px" : "100px",
      //   position: isPageNotFound ? "unset" : "fixed",
      //   bottom: isPageNotFound && "0px"
      // }}
      >
        <div className={"footer-head content-center"}>
          <h1>LET’S CAPTURE YOUR <span className="color">EVENT IN FULL GLORY</span></h1>
          <a href="#about-contact"><button className="btn1">
              <i><img src={paper_plane} alt="" /></i>
              <span className="text">
                  Get A Quote
              </span>
              <span className="loading-animate"></span>
          </button></a>
        </div>
        <p className="copyRight">Copyright © 2024 Tad Network | All Rights Reserved.</p>
    </footer>
  )
}
