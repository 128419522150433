import React from 'react'
import paper_plane from "../../Assets/Common/paper-plane.webp"

import "./Footer1.css"

export default function Footer1() {
  return (
    <footer className="footer1">
        <div className="footer-head content-center">
          <h1>LET’S CAPTURE YOUR <span className="color">EVENT IN FULL GLORY</span></h1>
          <a href="/"><button className="btn1">
              <i><img src={paper_plane} alt="" /></i>
              <span className="text">
                  Explore TAD
              </span>
              <span className="loading-animate"></span>
          </button></a>
        </div>
        <p className="copyRight">Copyright © 2024 Tad Network | All Rights Reserved.</p>
    </footer>
  )
}
