import { motion } from "framer-motion"

// import bg from "../../Assets/Common/bg3.jpg"

import "./Section2.css"

export default function Section2() {
    return (
        <section id="section2" className="section2 container">
            <div className="section2-body">
                {/* <motion.div 
                    initial={{
                        opacity: 0,
                        y: -100
                    }}
                    whileInView={{
                        opacity: 1,
                        y: 0
                    }}
                    viewport={{ once: false, amount: 0.5 }}
                    transition={{ 
                        duration: 1,
                        ease: "easeOut",
                        delay: 0.2
                    }}
                    className="top">
                    <img src={bg} alt="" />
                </motion.div> */}
                <div className="overlay"></div>
                <div className="bottom">
                    <motion.div 
                        initial={{
                            opacity: 0,
                            x: -100
                        }}
                        whileInView={{
                            opacity: 1,
                            x: 0
                        }}
                        viewport={{ once: false, amount: 0.5 }}
                        transition={{ 
                            duration: 1,
                            ease: "easeOut",
                            delay: 0.2
                        }}
                        className="left">
                        <h2>VIDEOS TO CAPTURE MOMENTS<br/><span className="color">AND EXPRESS THE EMOTIONS</span></h2>
                        <p>We don’t shoot footage; we capture the emotions in our frames. 
                            At Tad Video Production, we pride ourselves on being the first choice 
                            for event videography and photography in Dubai. Our team of seasoned event 
                            video coverage specialists, photographers, and film producers is adept at capturing, 
                            editing, and crafting various event videos. From weddings and family gatherings to corporate events and concerts, 
                            we create videos to sync with the theme and goals of the event. </p>
                    </motion.div>
                    <motion.div 
                        initial={{
                            opacity: 0,
                            x: 100
                        }}
                        whileInView={{
                            opacity: 1,
                            x: 0
                        }}
                        viewport={{ once: false, amount: 0.5 }}
                        transition={{ 
                            duration: 1,
                            ease: "easeOut",
                            delay: 0.2
                        }}    
                        className="right">
                        <h2><span className="color">LET’S CREATE UNFORGETTABLE</span><br/> MOMENTS TOGETHER</h2>
                        <p>We understand the significance of your special moments. Whether it is a family 
                            celebration or a product launch for your brand. As experts in video production, 
                            we’re here to ensure they’re not just remembered but let you relive those memories 
                            through the magic of video. With corporate and promotional videos, we conceptualize 
                            every frame and plan meticulously to capture the story and tell your story as you’d 
                            like to be told. Our team uses state-of-the-art equipment and editing tools to create the most fascinating visuals.</p>
                    </motion.div>
                </div>
            </div>
        </section>
    )
}