import React from 'react'
import "./Navbar1.css"

import logo from "../../Assets/Common/TAD-production-logo-new.webp"

import paper_plane from "../../Assets/Common/paper-plane.webp"

const Navbar1 = () => {
  return (
    <div className="navbar1">
      <nav className="container dark-nav">
      <a href="/"><img src={logo} alt="" className="logo"></img></a>
      {/* <div className="flag-number">
        <img src={flag} alt="" className="flag"/><a href="tel:+971542226464" className="phone-number no-link-style">+971 54 222 6464</a>
      </div> */}
      <a href="/"><button className="btn1">
        <i><img src={paper_plane} alt="" /></i>
        <span className="text">
          CONTACT US
        </span>
        <span className="loading-animate"></span>
      </button></a>
    </nav>
    </div>
  )
}

export default Navbar1