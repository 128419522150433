import React, { useState } from 'react'
import { Card, CardImg, CardBody, CardTitle } from 'reactstrap';
import { motion } from 'framer-motion';

import "./Stages.css"

import paper_plane from "../../Assets/Common/paper-plane.webp"

import first1 from "../../Assets/Stages/1/1.webp"
import first2 from "../../Assets/Stages/1/2.webp"
import first3 from "../../Assets/Stages/1/3.webp"
import first4 from "../../Assets/Stages/1/4.webp"


import second1 from "../../Assets/Stages/2/1.webp"
import second2 from "../../Assets/Stages/2/2.webp"
import second3 from "../../Assets/Stages/2/3.webp"
import second4 from "../../Assets/Stages/2/4.webp"

import third1 from "../../Assets/Stages/3/1.webp"
import third2 from "../../Assets/Stages/3/2.webp"
import third3 from "../../Assets/Stages/3/3.webp"
import third4 from "../../Assets/Stages/3/4.webp"

import fourth1 from "../../Assets/Stages/4/1.webp"
import fourth2 from "../../Assets/Stages/4/2.webp"
import fourth3 from "../../Assets/Stages/4/3.webp"
import fourth4 from "../../Assets/Stages/4/4.webp"

const gridContainerVariants = {
    hidden: {
        opacity: 0
    },
    show: {
        opacity: 1,
        transition: {
            staggerChildren: .3,
        }
    }
}

const gridSquareVariants = {
    hidden: {
        opacity: 0,
        y: 20
    },
    show: {
        opacity: 1,
        y: 0,
        transition: {
            duration: .5,
        }
    }
}

function Stages() {
    const [activeSection, setActiveSection] = useState('awareness-stage');

    const handleSectionChange = (section) => {
      setActiveSection(section);
    }

  return (
    <section id="stages" className="stages container">
        {/* <div className="underline"><span></span></div> */}
        <div className="stages-head content-center">
            <motion.h1
                initial={{
                    opacity: 0,
                    y: -100
                  }}
                  whileInView={{
                      opacity: 1,
                      y: 0
                  }}
                  transition={{
                      duration: 1,
                      ease: "easeOut",
                      delay: 0.4
                  }}><span className="color">VIDEO SOLUTIONS</span><br/>FOR EVERY STAGE OF THE FUNNEL</motion.h1>
            <p>If you aim to enhance brand visibility, generate more leads, and boost sales, 
                a well-structured marketing funnel is essential. Yet, if you haven’t incorporated 
                top-notch explainer videos into your funnel, you’re overlooking a significant 
                chance to engage your audience and spur conversions.</p>
        </div>
            <div className="button-container1 content-center">
                <div
                    className={`btn btn-gradient gradient2 ${activeSection === 'awareness-stage' ? 'active' : ''}`}
                    onClick={() => handleSectionChange('awareness-stage')}
                    >
                    Awareness Stage
                </div>
                <div
                    className={`btn btn-gradient gradient2 ${activeSection === 'interest-stage' ? 'active' : ''}`}
                    onClick={() => handleSectionChange('interest-stage')}
                    >
                    Interest Stage
                </div>
                <div
                    className={`btn btn-gradient gradient2 ${activeSection === 'consideration-stage' ? 'active' : ''}`}
                    onClick={() => handleSectionChange('consideration-stage')}
                    >
                    Consideration Stage
                </div>
                <div
                    className={`btn btn-gradient gradient2 ${activeSection === 'decision-stage' ? 'active' : ''}`}
                    onClick={() => handleSectionChange('decision-stage')}
                    >
                    Decision Stage
                </div>
            </div>

            {activeSection === "awareness-stage" && (
                <motion.div 
                    key={activeSection}
                    variants={gridContainerVariants}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: false, amount: 0.5 }}
                    className="card-wrapper">
                    <motion.Card 
                        variants={gridSquareVariants}
                        viewport={{ once: false, amount: 0.5 }}
                        className="card">
                        <div className="card-image">
                        <CardImg
                            top
                            src={first1}
                            alt="profile-card"
                            className="profile-img"
                        />
                        </div>
                        <motion.CardBody 
                            initial={{
                                    opacity: 0,
                                    y: 50
                                }}
                                whileInView={{
                                    opacity: 1,
                                    y: 0
                                }}
                                transition={{
                                    duration: 1,
                                    ease: "easeOut",
                                    delay: 0.2
                                }}
                                viewport={{ once: false, amount: 0.5 }}
                                className="details">
                            <CardTitle tag="h2">
                                Explainer Video
                                <br />
                            </CardTitle>
                        </motion.CardBody>
                    </motion.Card>
                    <motion.Card 
                        variants={gridSquareVariants}
                        viewport={{ once: false, amount: 0.5 }}
                        className="card">
                        <div className="card-image">
                        <CardImg
                            top
                            src={first2}
                            alt="profile-card"
                            className="profile-img"
                        />
                        </div>
                        <motion.CardBody 
                            initial={{
                                    opacity: 0,
                                    y: 50
                                }}
                                whileInView={{
                                    opacity: 1,
                                    y: 0
                                }}
                                transition={{
                                    duration: 1,
                                    ease: "easeOut",
                                    delay: .4
                                }}
                                viewport={{ once: false, amount: 0.5 }}
                                className="details">
                            <CardTitle tag="h2">
                                Social Media Videos
                                <br />
                            </CardTitle>
                        </motion.CardBody>
                    </motion.Card>
                    <motion.Card 
                        variants={gridSquareVariants}
                        viewport={{ once: false, amount: 0.5 }}
                        className="card">
                        <div className="card-image">
                        <CardImg
                            top
                            src={first3}
                            alt="profile-card"
                            className="profile-img"
                        />
                        </div>
                        <motion.CardBody 
                            initial={{
                                    opacity: 0,
                                    y: 50
                                }}
                                whileInView={{
                                    opacity: 1,
                                    y: 0
                                }}
                                transition={{
                                    duration: 1,
                                    ease: "easeOut",
                                    delay: .6
                                }}
                                viewport={{ once: false, amount: 0.5 }}
                                lassName="details">
                            <CardTitle tag="h2">
                                Brand Videos
                                <br />
                            </CardTitle>
                        </motion.CardBody>
                    </motion.Card>
                    <motion.Card 
                        variants={gridSquareVariants}
                        viewport={{ once: false, amount: 0.5 }}
                        className="card">
                        <div className="card-image">
                        <CardImg
                            top
                            src={first4}
                            alt="profile-card"
                            className="profile-img"
                        />
                        </div>
                        <motion.CardBody 
                            initial={{
                                    opacity: 0,
                                    y: 50
                                }}
                                whileInView={{
                                    opacity: 1,
                                    y: 0
                                }}
                                transition={{
                                    duration: 1,
                                    ease: "easeOut",
                                    delay: .8
                                }}
                                viewport={{ once: false, amount: 0.5 }}
                                className="details">
                            <CardTitle tag="h2">
                                Educational Videos
                                <br />
                            </CardTitle>
                        </motion.CardBody>
                    </motion.Card>
                </motion.div>
            )}

            {activeSection === "interest-stage" && (
                <motion.div 
                variants={gridContainerVariants}
                initial="hidden"
                whileInView="show"
                viewport={{ once: false, amount: 0.5 }}
                className="card-wrapper">
                <motion.Card 
                    variants={gridSquareVariants}
                    viewport={{ once: false, amount: 0.5 }}
                    className="card">
                    <div className="card-image">
                    <CardImg
                        top
                        src={second1}
                        alt="profile-card"
                        className="profile-img"
                    />
                    </div>
                    <motion.CardBody 
                        initial={{
                                opacity: 0,
                                y: 50
                            }}
                            whileInView={{
                                opacity: 1,
                                y: 0
                            }}
                            transition={{
                                duration: 1,
                                ease: "easeOut",
                                delay: 0.2
                            }}
                            viewport={{ once: false, amount: 0.5 }}
                            className="details">
                        <CardTitle tag="h2">
                            Company OverView Video
                            <br />
                        </CardTitle>
                    </motion.CardBody>
                </motion.Card>
                <motion.Card 
                    variants={gridSquareVariants}
                    viewport={{ once: false, amount: 0.5 }}
                    className="card">
                    <div className="card-image">
                    <CardImg
                        top
                        src={second2}
                        alt="profile-card"
                        className="profile-img"
                    />
                    </div>
                    <motion.CardBody 
                        initial={{
                                opacity: 0,
                                y: 50
                            }}
                            whileInView={{
                                opacity: 1,
                                y: 0
                            }}
                            transition={{
                                duration: 1,
                                ease: "easeOut",
                                delay: .4
                            }}
                            viewport={{ once: false, amount: 0.5 }}
                            className="details">
                        <CardTitle tag="h2">
                            Behind the Scene Videos
                            <br />
                        </CardTitle>
                    </motion.CardBody>
                </motion.Card>
                <motion.Card 
                    variants={gridSquareVariants}
                    viewport={{ once: false, amount: 0.5 }}
                    className="card">
                    <div className="card-image">
                    <CardImg
                        top
                        src={second3}
                        alt="profile-card"
                        className="profile-img"
                    />
                    </div>
                    <motion.CardBody 
                        initial={{
                                opacity: 0,
                                y: 50
                            }}
                            whileInView={{
                                opacity: 1,
                                y: 0
                            }}
                            transition={{
                                duration: 1,
                                ease: "easeOut",
                                delay: .6
                            }}
                            viewport={{ once: false, amount: 0.5 }}
                            lassName="details">
                        <CardTitle tag="h2">
                            Event Videos
                            <br />
                        </CardTitle>
                    </motion.CardBody>
                </motion.Card>
                <motion.Card 
                    variants={gridSquareVariants}
                    viewport={{ once: false, amount: 0.5 }}
                    className="card">
                    <div className="card-image">
                    <CardImg
                        top
                        src={second4}
                        alt="profile-card"
                        className="profile-img"
                    />
                    </div>
                    <motion.CardBody 
                        initial={{
                                opacity: 0,
                                y: 50
                            }}
                            whileInView={{
                                opacity: 1,
                                y: 0
                            }}
                            transition={{
                                duration: 1,
                                ease: "easeOut",
                                delay: .8
                            }}
                            viewport={{ once: false, amount: 0.5 }}
                            className="details">
                        <CardTitle tag="h2">
                            Customer testimonials Videos
                            <br />
                        </CardTitle>
                    </motion.CardBody>
                </motion.Card>
            </motion.div>
            )}

            {activeSection === "consideration-stage" && (
                <motion.div 
                    variants={gridContainerVariants}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: false, amount: 0.5 }}
                    className="card-wrapper">
                    <motion.Card 
                        variants={gridSquareVariants}
                        viewport={{ once: false, amount: 0.5 }}
                        className="card">
                        <div className="card-image">
                        <CardImg
                            top
                            src={third1}
                            alt="profile-card"
                            className="profile-img"
                        />
                        </div>
                        <motion.CardBody 
                            initial={{
                                    opacity: 0,
                                    y: 50
                                }}
                                whileInView={{
                                    opacity: 1,
                                    y: 0
                                }}
                                transition={{
                                    duration: 1,
                                    ease: "easeOut",
                                    delay: 0.2
                                }}
                                viewport={{ once: false, amount: 0.5 }}
                                className="details">
                            <CardTitle tag="h2">
                                Product Demo Video
                                <br />
                            </CardTitle>
                        </motion.CardBody>
                    </motion.Card>
                    <motion.Card 
                        variants={gridSquareVariants}
                        viewport={{ once: false, amount: 0.5 }}
                        className="card">
                        <div className="card-image">
                        <CardImg
                            top
                            src={third2}
                            alt="profile-card"
                            className="profile-img"
                        />
                        </div>
                        <motion.CardBody 
                            initial={{
                                    opacity: 0,
                                    y: 50
                                }}
                                whileInView={{
                                    opacity: 1,
                                    y: 0
                                }}
                                transition={{
                                    duration: 1,
                                    ease: "easeOut",
                                    delay: 0.4
                                }}
                                viewport={{ once: false, amount: 0.5 }}
                                className="details">
                            <CardTitle tag="h2">
                                Customer testimonials Videos
                                <br />
                            </CardTitle>
                        </motion.CardBody>
                    </motion.Card>
                    <motion.Card 
                        variants={gridSquareVariants}
                        viewport={{ once: false, amount: 0.5 }}
                        className="card">
                        <div className="card-image">
                        <CardImg
                            top
                            src={third3}
                            alt="profile-card"
                            className="profile-img"
                        />
                        </div>
                        <motion.CardBody 
                            initial={{
                                    opacity: 0,
                                    y: 50
                                }}
                                whileInView={{
                                    opacity: 1,
                                    y: 0
                                }}
                                transition={{
                                    duration: 1,
                                    ease: "easeOut",
                                    delay: .6
                                }}
                                viewport={{ once: false, amount: 0.5 }}
                                lassName="details">
                            <CardTitle tag="h2">
                                Comparison Videos
                                <br />
                            </CardTitle>
                        </motion.CardBody>
                    </motion.Card>
                    <motion.Card 
                        variants={gridSquareVariants}
                        viewport={{ once: false, amount: 0.5 }}
                        className="card">
                        <div className="card-image">
                        <CardImg
                            top
                            src={third4}
                            alt="profile-card"
                            className="profile-img"
                        />
                        </div>
                        <motion.CardBody 
                            initial={{
                                    opacity: 0,
                                    y: 50
                                }}
                                whileInView={{
                                    opacity: 1,
                                    y: 0
                                }}
                                transition={{
                                    duration: 1,
                                    ease: "easeOut",
                                    delay: .8
                                }}
                                viewport={{ once: false, amount: 0.5 }}
                                className="details">
                            <CardTitle tag="h2">
                                How-To Videos
                                <br />
                            </CardTitle>
                        </motion.CardBody>
                    </motion.Card>
                </motion.div>
            )}

            {activeSection === "decision-stage" && (
                <motion.div 
                variants={gridContainerVariants}
                initial="hidden"
                whileInView="show"
                viewport={{ once: false, amount: 0.5 }}
                className="card-wrapper">
                <motion.Card 
                    variants={gridSquareVariants}
                    viewport={{ once: false, amount: 0.5 }}
                    className="card">
                    <div className="card-image">
                    <CardImg
                        top
                        src={fourth1}
                        alt="profile-card"
                        className="profile-img"
                    />
                    </div>
                    <motion.CardBody 
                        initial={{
                                opacity: 0,
                                y: 50
                            }}
                            whileInView={{
                                opacity: 1,
                                y: 0
                            }}
                            transition={{
                                duration: 1,
                                ease: "easeOut",
                                delay: 0.2
                            }}
                            viewport={{ once: false, amount: 0.5 }}
                            className="details">
                        <CardTitle tag="h2">
                            Sales Video
                            <br />
                        </CardTitle>
                    </motion.CardBody>
                </motion.Card>
                <motion.Card 
                    variants={gridSquareVariants}
                    viewport={{ once: false, amount: 0.5 }}
                    className="card">
                    <div className="card-image">
                    <CardImg
                        top
                        src={fourth2}
                        alt="profile-card"
                        className="profile-img"
                    />
                    </div>
                    <motion.CardBody 
                        initial={{
                                opacity: 0,
                                y: 50
                            }}
                            whileInView={{
                                opacity: 1,
                                y: 0
                            }}
                            transition={{
                                duration: 1,
                                ease: "easeOut",
                                delay: 0.4
                            }}
                            viewport={{ once: false, amount: 0.5 }}
                            className="details">
                        <CardTitle tag="h2">
                            Limited Time Offer Videos
                            <br />
                        </CardTitle>
                    </motion.CardBody>
                </motion.Card>
                <motion.Card 
                    variants={gridSquareVariants}
                    viewport={{ once: false, amount: 0.5 }}
                    className="card">
                    <div className="card-image">
                    <CardImg
                        top
                        src={fourth3}
                        alt="profile-card"
                        className="profile-img"
                    />
                    </div>
                    <motion.CardBody 
                        initial={{
                                opacity: 0,
                                y: 50
                            }}
                            whileInView={{
                                opacity: 1,
                                y: 0
                            }}
                            transition={{
                                duration: 1,
                                ease: "easeOut",
                                delay: .6
                            }}
                            viewport={{ once: false, amount: 0.5 }}
                            lassName="details">
                        <CardTitle tag="h2">
                            Webinar Videos
                            <br />
                        </CardTitle>
                    </motion.CardBody>
                </motion.Card>
                <motion.Card 
                    variants={gridSquareVariants}
                    viewport={{ once: false, amount: 0.5 }}
                    className="card">
                    <div className="card-image">
                    <CardImg
                        top
                        src={fourth4}
                        alt="profile-card"
                        className="profile-img"
                    />
                    </div>
                    <motion.CardBody 
                        initial={{
                                opacity: 0,
                                y: 50
                            }}
                            whileInView={{
                                opacity: 1,
                                y: 0
                            }}
                            transition={{
                                duration: 1,
                                ease: "easeOut",
                                delay: .8
                            }}
                            viewport={{ once: false, amount: 0.5 }}
                            className="details">
                        <CardTitle tag="h2">
                            OnBoarding Videos
                            <br />
                        </CardTitle>
                    </motion.CardBody>
                </motion.Card>
            </motion.div>
            )}
            <div className="content-center">
                <a href="#about-contact"><button className="btn1">
                    {/* <FontAwesomeIcon icon="fa-solid fa-paper-plane" /> */}
                    <i><img src={paper_plane} alt="" /></i>
                    <span className="text">
                        Get A Quote
                    </span>
                    <span className="loading-animate"></span>
                </button></a>
            </div>

    </section>
  )
}

export default Stages