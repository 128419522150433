import { motion } from "framer-motion"

import "./Professionals.css"

const gridContainerVariants = {
    hidden: {
        opacity: 0
    },
    show: {
        opacity: 1,
        transition: {
            staggerChildren: .5,
        }
    }
}

const gridSquareVariants = {
    hidden: {
        opacity: 0,
    },
    show: {
        opacity: 1,
    }
}

export default function Professionals(){
    return (
        <div id="our-vision" className="professionals container">
            <h1 className="commonHeading">WHY HIRE PROFESSIONALS<br/><span className="color">FOR EVENT VIDEOGRAPHY</span></h1>
            <div className="professional-body">
            <div className="overlay"></div>
                <motion.div 
                    variants={gridContainerVariants}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: false, amount: 0.5 }}
                    className="left">
                    <motion.div 
                        variants={gridSquareVariants}
                        viewport={{ once: false, amount: 0.5 }}
                        className="row item1">
                        <motion.h1 
                            initial={{
                                opacity: 0,
                                x: -100
                            }}
                            whileInView={{
                                opacity: 1,
                                x: 0
                            }}
                            transition={{
                                duration: 1,
                                ease: "easeOut",
                                delay: 0
                            }}>CREATIVE VISION</motion.h1>
                        <p>We bring a creative vision to your event, turning it into a visually compelling story. Our team knows how to frame shots, use angles, and choose the right moments to capture, creating a captivating narrative. From lighting to audio and composition to color balance, you can rely on us to capture the soul of the event in our frames.</p>
                    </motion.div>
                    <motion.div 
                        variants={gridSquareVariants}
                        viewport={{ once: false, amount: 0.5 }}
                        className="row item2">
                        <motion.h1
                            initial={{
                                opacity: 0,
                                x: -100
                            }}
                            whileInView={{
                                opacity: 1,
                                x: 0
                            }}
                            transition={{
                                duration: 1,
                                ease: "easeOut",
                                delay: .5
                            }}>EDITING SKILLS</motion.h1>
                        <p>Post-production is a crucial part of videography. The best stories are created on the editing table. We have a team of experts who can transform raw footage into engaging and entertaining video products. We add music, graphics, and effects to enhance the video and make it memorable for our clients.</p>
                    </motion.div>
                    <motion.div 
                        variants={gridSquareVariants}
                        viewport={{ once: false, amount: 0.5 }}
                        className="row item3">
                        <motion.h1
                            initial={{
                                opacity: 0,
                                x: -100
                            }}
                            whileInView={{
                                opacity: 1,
                                x: 0
                            }}
                            transition={{
                                duration: 1,
                                ease: "easeOut",
                                delay: 1
                            }}>MULTIPLE CAMERA ANGLES</motion.h1>
                        <p>One frame doesn’t always tell the story, and we use multiple cameras to capture different angles simultaneously. Whether it is the audience's reaction at your corporate or a family member’s emotion during a memorable day, we use multiple cameras to present the most comprehensive view of the event.</p>
                    </motion.div>
                </motion.div>
                {/* <motion.div 
                    initial={{
                        opacity: 0,
                        x: 100
                    }}
                    whileInView={{
                        opacity: 1,
                        x: 0
                    }}
                    transition={{
                        duration: 1,
                        ease: "easeOut",
                        delay: .2
                    }}
                    viewport={{ once: false, amount: 0.5 }}
                    className="right">
                    <img src={bg} alt="" />
                </motion.div> */}
            </div>
        </div>
    )
}