import React, { useEffect } from "react";
import "./Thankyou.css";
import thankyou_icon from "../../Assets/Common/thankyouimage.webp";
// import facebook from "../Assets/Social-Links/facebook.svg";
// import instagram from "../Assets/Social-Links/instagram.svg";
// import youtube from "../Assets/Social-Links/youtube.svg";
// import twitter from "../Assets/Social-Links/twitter.svg";
// import linkedin from "../Assets/Social-Links/linkedin.svg";
import Helmet from "../../Components/Helmet/Helmet";

export default function Thankyou() {
    return (
        <>
            <Helmet title="Thank You">
                {/* Page metadata */}
                <script type="text/javascript">
                    {`
                        gtag('event', 'conversion', {
                        'send_to': 'AW-11293968331/_I6WCK-Um9sZEMuPsYkq',
                        'value': 1.0,
                        'currency': 'AED'
                    });
                    `}
                </script>
                <section id="thankyou" className="thankyou">
                <div>
                    <img className="thankyou-icon" src={thankyou_icon} alt="Thank you"/>
                </div>
                <h1><span className="color">THANK</span> YOU</h1>
                <p>If you have any questions, feedback, or need assistance with anything,<br/>
                    please don’t hesitate to reach out to our dedicated customer support team. We’re here to help.</p>
                {/* <a href="/videoproduction"><p className="phone">+971 54 222 6464</p></a> */}
                {/* <div className="social-links">
                    <a href="https://www.facebook.com/profile.php?id=61565494103415/" alt=""><img className='social' src={facebook} alt=""/></a>
                    <a href="https://www.instagram.com/rabbit_nd_carrot/" alt=""><img className='social' src={instagram} alt=""/></a>
                    <a href="https://www.youtube.com/@RabbitandCarrot-z5l/" alt=""><img className='social' src={youtube} alt=""/></a>
                    <a href="https://x.com/rabbitndcarrot/" alt=""><img className='social' src={twitter} alt=""/></a>
                    <a href="https://www.linkedin.com/company/rabbitandcarrot/" alt=""><img className='social' src={linkedin} alt=""/></a>
                </div>  */}
            </section>
            </Helmet>
        </>
    );
}
