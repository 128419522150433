import React, { useEffect, useRef, useState } from 'react'
import ReactIframe from 'react-iframe'
import { motion, AnimatePresence } from 'framer-motion'

import "./AboutUs.css"

import paper_plane from "../../Assets/Common/paper-plane.webp"

const textVariants = {
    initial: { x: -100, opacity: 0 },
    animate: { x: 0, opacity: 1, transition: { duration: 1, staggerChildren: 0.1 } },
    exit: { x: 100, opacity: 0, transition: { duration: .5 } }
  };

function About() {
    const [isVisible, setIsVisible] = useState(false);
    const iframeRef = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(
        ([entry]) => setIsVisible(entry.isIntersecting),
        { threshold: 0.1 }
        );
        if (iframeRef.current) {
        observer.observe(iframeRef.current);
        }
        return () => observer.disconnect();
    }, []);
    
    // useEffect(() => {
    //     // Add the Zoho form script dynamically
    //     const script = document.createElement('script');
    //     script.src = 'https://campaigns.zoho.com/js/optin.min.js';
    //     script.type = 'text/javascript';
    //     script.id = 'ZC_Forms_Popup';
    //     document.body.appendChild(script);
    
    //     // Callback for loading the form after the script is loaded
    //     script.onload = () => {
    //       if (window.loadZCPopup) {
    //         window.loadZCPopup(
    //           '3z6e3da9b068fc51608c2f5d282e8ea8c86de1f428bbdf9fb9c81869a886e411f2',
    //           'ZCFORMVIEW',
    //           '3z7a4a314b02af22e2b4b2a89ea4ae8bc9'
    //         );
    //       }
    //     };
    
    //     // Clean up the script when the component is unmounted
    //     return () => {
    //       document.body.removeChild(script);
    //     };
    //   }, []);
  return (
        <section id="about-contact" className='aboutHome container'>
            <div className='flexSB'>
                <div className='left'>
                <AnimatePresence mode="wait">
                    <motion.div 
                        initial="initial"
                        whileInView="animate"
                        transition={{
                          duration: 1,
                          ease: "easeOut",
                          delay: 0.2
                        }}
                        exit="exit"
                        // viewport={{ once: false, amount: 0.5 }}
                        variants={textVariants}
                        className="aboutHeading">
                        <motion.h1><span className="color">EVENT VIDEOGRAPHY</span> AND <span className="color">PHOTOGRAPHY</span> WITH OUR JAW-DROPPING <span className="color">PRICE-TAGS</span></motion.h1>
                        <motion.p>Capture the memories and cherish them for a lifetime or make your brand stand 
                            out with captivating visuals. At Tad Video Production, 
                            we create the most stunning videos for corporate and private events. 
                            Whether you are celebrating your life’s milestone or want to promote your brand with stunning visuals, 
                            we have made capturing memories just more affordable! Our event videography and photography services come 
                            at jaw-dropping price tags that won’t break the bank. </motion.p>
                        <motion.a href="#services"><button className="btn1">
                            <i><img src={paper_plane} alt="" /></i>
                            <span className="text">
                                EXPLORE TAD
                            </span>
                            <span className="loading-animate"></span>
                        </button></motion.a>
                    </motion.div>
                </AnimatePresence>
                </div>
                <div className="right" ref={iframeRef}>
                    {isVisible && (
                        <ReactIframe
                            aria-label='Get A Quote' 
                            frameborder="0"
                            style={{ padding: '20px', border: 'none' }}
                            src="https://forms.zohopublic.com/virtualoffice10491/form/VideoProduction/formperma/-H38tEJ_TiUCIBXdOFnQDpsG1bwnbCQysu36khyQUUM"
                            width="100%" 
                            height="546.5px"
                        />
                    )}
                </div>
            </div>
        </section>
  )
}

export default About