import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "./Clients.css"

import client1 from "../../Assets/Clients/1.webp"
import client2 from "../../Assets/Clients/2.webp"
import client3 from "../../Assets/Clients/3.webp"
import client4 from "../../Assets/Clients/4.webp"
import client5 from "../../Assets/Clients/5.webp"

const data = [
    {
      img: client1,
    },
    {
      img: client2,
    },
    {
      img: client3,
    },
    {
      img: client4,
    },
    {
      img: client5,
    },  
  ];

function Client() {
    const settings = {
      dots: false,
      arrows: false,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 2000,
      cssEase: "linear",
      responsive: [
        {
        breakpoint: 1024,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
        }
        },
        {
        breakpoint: 600,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
        }
        },
        {
        breakpoint: 480,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
        }
        }
      ]
      };
    return (
      <section className="container client">
          <h1 className="commonHeading">OVER <span className="color">5000 VIDEOS<br/>UTILIZED </span> BY COMPANIES LIKE</h1>
              <div className="client-body">
                  <Slider {...settings}>
                      {data.map((d) => (
                          <div key={d.img} className="client-image">
                              <img src={d.img} alt="" className=""/>
                          </div>
                      ))} 
                  </Slider>
              </div>
      </section>
    );
}

export default Client;