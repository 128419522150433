import React, { useState } from 'react';
import { motion,AnimatePresence } from 'framer-motion';

import './PopupForm.css';
import logo from "../../Assets/Common/TAD-production-logo-new.webp"
import closeButton from "../../Assets/Common/close.webp"
import paper_plane from "../../Assets/Common/paper-plane.webp"
// import fliplogo from "../../Assets/Common/fliplogo.gif"

const access_key = "0031ea64-e82a-400c-b193-b60dc273c1f5" // Aashiyana
// const access_key = "f65ef0eb-57aa-45ba-91f7-aaf00dfe46ee" // Sinan

function PopupForm({ handleClose }) {
    const [result, setResult] = useState("");
    const [otherOption, setOtherOption] = useState(false)

    const [loading, setLoading] = useState(false);

  const handleServiceChange = (event) => {
    setOtherOption(event.target.value === "Other")
  }
 
  const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setTimeout(() => {
        setLoading(false);
        }, 1700); // Simulate a loading state for 3 seconds
        
        setResult("Sending....");
        const formData = new FormData(event.target);

        formData.append("access_key", access_key);

        const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData
        });

        const data = await response.json();

        if (data.success) {
        setResult("Form Submitted Successfully");
        setTimeout(() => {
          handleClose()
        }, 2000)
        event.target.reset();
        } else {
        console.log("Error", data);
        setResult(data.message);
      }
  };
  return (
    <AnimatePresence>
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        className="modal-overlay">
        <motion.div 
          initial={{ scale: 0.5 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0.8 }}
          transition={{ duration: 0.5 }}
          className={`modal-content  ${ otherOption ? "height" : ""}`}>
          <img src={closeButton} alt="" className="closeButton" onClick={() => { handleClose() }}/>
          <div className="left">
              {/* <h1>rabbotandcarrot</h1> */}
              <img src={logo} alt="" />
              <p>We’re all about creating communications that sparkle and 
                shine for the brands we love. 
                But there’s more to us than just creating pretty pictures.
              </p>
          </div>
          <div className="right">
            <div className="contact-col">
              <h1>Get <span className="color">In Touch</span></h1>
              <form onSubmit={handleSubmit}>
                  {/* <label>Your Name</label> */}
                  <input type="text" name="name" placeholder="Enter Your Name" required/>
                  {/* <label>Phone Number</label> */}
                  <input type="tel" name="phone" placeholder="Enter Your Mobile" required/>
                  {/* <label>Email</label> */}
                  <input type="email" name="email" placeholder="Enter Your Email" required/>
                  <select name="services" onChange={handleServiceChange} required>
                    <option value="">Services</option>
                    <option value="2D Animation">2D Animation</option>
                    <option value="3D Animation">3D Animation</option>
                    <option value="GCI Videos">GCI Videos</option>
                    <option value="Live Action">Live Action</option>
                    <option value="Visual Production">Visual Production</option>
                    <option value="Videography & Porography">Videography & Photography</option>
                    <option value="Post Production">Post Production</option>
                    <option value="Other">Other</option>
                  </select>
                  { otherOption && <input type="text" name="otherServices" placeholder="Enter Your Requirements" required/>}
                  <button
                      className={`btn1 ${loading ? 'loading' : ''}`}
                      disabled={loading}
                  >
                      <i><img src={paper_plane} alt="" /></i>
                      <span className="text">
                          { loading ? "Sending" : "Submit"}
                      </span>
                      <span className="loading-animate"></span>
                  </button>
              </form>
              <span className="result">{result}</span>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
}

export default PopupForm;