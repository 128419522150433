import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./Services.css";

import service1 from "../../Assets/Services/2d.webp";
import service2 from "../../Assets/Services/3d.webp";
import service3 from "../../Assets/Services/cgi.webp";
import service4 from "../../Assets/Services/action.webp";
import service5 from "../../Assets/Services/visual.webp";
import service6 from "../../Assets/Services/video.webp";
import service7 from "../../Assets/Services/post.webp";

// const imageVarient = {
//     initial: {
//         opacity: 0,
//          y: 100
//     },
//     animate: {
//         opacity: 0,
//          x: 0
//     },
//     transition: {
//         duration: 1,
//         ease: "easeOut",
//         delay: 0.2
//     }
// }

export default function Services() {
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768);
    const [activeSection, setActiveSection] = useState(isLargeScreen ? "2d-animation" : null);
    const [isDetailsOpen, setIsDetailsOpen] = useState(isLargeScreen ? true : false);

  useEffect(() => {
        const handleResize = () => setIsLargeScreen(window.innerWidth > 768);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

  const handleSectionChange = (section) => {
    if (activeSection === section && isDetailsOpen) {
        setIsDetailsOpen(false); // Close details if clicked again
    } else {
        setActiveSection(section);
        setIsDetailsOpen(true);
    }
  };

  return (
        <section id="services" className="services container">
        <motion.h1 
            initial={{
                opacity: 0,
                y: -100
              }}
              whileInView={{
                  opacity: 1,
                  y: 0
              }}
              transition={{
                  duration: 1,
                  ease: "easeOut",
                  delay: 0.3
              }}
            className="commonHeading">
            <span className="color">TAILORED SERVICES</span>
            <br />
            FOR EVERY STEP OF THE <span className="color">CUSTOMER JOURNEY</span>
        </motion.h1>
        <div className="services-container">
            <div className={`button-container1 content-center ${isDetailsOpen ? "hide-buttons" : ""}`}>
            <button
                className={`btn btn-gradient gradient2 ${activeSection === '2d-animation' ? 'active' : ''}`}
                onClick={() => handleSectionChange('2d-animation')}
                disabled={isLargeScreen && activeSection === '2d-animation'}
            >
                2D ANIMATION
            </button>
            {activeSection === '2d-animation' && (
                <div className="details-container details-container-mobile">
                    <div className="left">
                        <motion.img
                            initial={{
                                        opacity: 0,
                                        y: -100
                                    }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0
                                    }}
                                    viewport={{ once: false, amount: 0.5 }}
                                    transition={{ 
                                        duration: 1,
                                        ease: "easeOut",
                                        delay: 0.2
                                    }}
                            src={service1} alt="" />
                    </div>
                    <div className="right">
                        <motion.p
                            initial={{
                                        opacity: 0,
                                        y: 100
                                    }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0
                                    }}
                                    viewport={{ once: false, amount: 0.5 }}
                                    transition={{ 
                                        duration: 1,
                                        ease: "easeOut",
                                        delay: 0.2
                                    }}
                        >Our 2D animation service is designed to elevate brand storytelling with a unique, hand-crafted touch. From character animations to kinetic typography, we create fluid, colorful visuals that engage and inform. Every element is meticulously animated to capture attention and convey your message with creativity and clarity, making 2D animation ideal for explainers, educational videos, and promotional content.</motion.p>
                    </div>
                </div>
            )}
            <button
                className={`btn btn-gradient gradient2 ${activeSection === '3d-animation' ? 'active' : ''}`}
                onClick={() => handleSectionChange('3d-animation')}
                disabled={isLargeScreen && activeSection === '3d-animation'}
            >
                3D ANIMATION
            </button>
            {activeSection === '3d-animation' && (
                <div className="details-container details-container-mobile">
                    <div className="left">
                        <motion.img
                            initial={{
                                        opacity: 0,
                                        y: -100
                                    }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0
                                    }}
                                    viewport={{ once: false, amount: 0.5 }}
                                    transition={{ 
                                        duration: 1,
                                        ease: "easeOut",
                                        delay: 0.2
                                    }}
                            src={service2} alt="" />
                    </div>
                    <div className="right">
                        <motion.p
                            initial={{
                                        opacity: 0,
                                        y: 50
                                    }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0
                                    }}
                                    viewport={{ once: false, amount: 0.5 }}
                                    transition={{ 
                                        duration: 1,
                                        ease: "easeOut",
                                        delay: 0.2
                                    }}
                        >3D animation allows you to showcase products, ideas, and worlds in lifelike, three-dimensional detail. Perfect for product visualizations, interactive simulations, and cinematic experiences, our 3D animation work combines technical expertise with artistic skill. By blending realistic textures, lighting, and motion, we create immersive animations that leave a lasting impression and bring your vision into a tangible, dynamic format.</motion.p>
                    </div>
                </div>
            )}
            <button
                className={`btn btn-gradient gradient2 ${activeSection === 'cgi' ? 'active' : ''}`}
                onClick={() => handleSectionChange('cgi')}
                disabled={isLargeScreen && activeSection === 'cgi'}
            >
                CGI
            </button>
            {activeSection === 'cgi' && (
                <div className="details-container details-container-mobile">
                    <div className="left">
                        <motion.img
                            initial={{
                                        opacity: 0,
                                        y: -100
                                    }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0
                                    }}
                                    viewport={{ once: false, amount: 0.5 }}
                                    transition={{ 
                                        duration: 1,
                                        ease: "easeOut",
                                        delay: 0.2
                                    }}
                            src={service3} alt="" />
                    </div>
                        <div className="right">
                    <motion.p
                        initial={{
                                        opacity: 0,
                                        y: 50
                                    }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0
                                    }}
                                    viewport={{ once: false, amount: 0.5 }}
                                    transition={{ 
                                        duration: 1,
                                        ease: "easeOut",
                                        delay: 0.2
                                    }}
                    >CGI(Computer-Generated Imagery) transforms the ordinary into the extraordinary, bringing fantasy elements and intricate visual effects into reality. Whether it’s creating a realistic product rendering or building a breathtaking virtual landscape, our CGI team works with precision and artistry to deliver high-quality visuals. Perfect for films, advertisements, and creative projects, CGI pushes the boundaries of what’s possible on screen, making your project unforgettable.</motion.p>
                    </div>
                </div>
            )}
            <button
                className={`btn btn-gradient gradient2 ${activeSection === 'live-action' ? 'active' : ''}`}
                onClick={() => handleSectionChange('live-action')}
                disabled={isLargeScreen && activeSection === 'live-action'}
            >
                LIVE ACTION
            </button>
            {activeSection === 'live-action' && (
                <div className="details-container details-container-mobile">
                    <div className="left">
                        <motion.img
                            initial={{
                                        opacity: 0,
                                        y: -100
                                    }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0
                                    }}
                                    viewport={{ once: false, amount: 0.5 }}
                                    transition={{ 
                                        duration: 1,
                                        ease: "easeOut",
                                        delay: 0.2
                                    }}
                            src={service4} alt="" />
                    </div>
                        <div className="right">
                    <motion.p
                        initial={{
                                        opacity: 0,
                                        y: 50
                                    }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0
                                    }}
                                    viewport={{ once: false, amount: 0.5 }}
                                    transition={{ 
                                        duration: 1,
                                        ease: "easeOut",
                                        delay: 0.2
                                    }}
                    >Our live action production captures real-life moments with cinematic flair. From corporate events to lifestyle videos, we use professional-grade equipment and artistic direction to highlight the authenticity of your story. Every shot, angle, and scene is carefully curated to enhance storytelling, creating a connection with viewers that feels both immediate and genuine.</motion.p>
                    </div>
                </div>
            )}
            <button
                className={`btn btn-gradient gradient2 ${activeSection === 'visual-production' ? 'active' : ''}`}
                onClick={() => handleSectionChange('visual-production')}
                disabled={isLargeScreen && activeSection === 'visual-production'}
            >
                VISUAL PRODUCTION
            </button>
            {activeSection === 'visual-production' && (
                <div className="details-container details-container-mobile">
                    <div className="left">
                        <motion.img
                            initial={{
                                        opacity: 0,
                                        y: -100
                                    }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0
                                    }}
                                    viewport={{ once: false, amount: 0.5 }}
                                    transition={{ 
                                        duration: 1,
                                        ease: "easeOut",
                                        delay: 0.2
                                    }}
                            src={service5} alt="" />
                    </div>
                        <div className="right">
                    <motion.p
                        initial={{
                                        opacity: 0,
                                        y: 50
                                    }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0
                                    }}
                                    viewport={{ once: false, amount: 0.5 }}
                                    transition={{ 
                                        duration: 1,
                                        ease: "easeOut",
                                        delay: 0.2
                                    }}
                    >Visual Production in a 3D animated environment places real people or characters within a digitally crafted world, blending the boundaries between reality and fantasy. This style is ideal for creating immersive, otherworldly experiences—perfect for brand activations, imaginative storytelling, or futuristic settings. Through expert integration of live elements in animated spaces, we build a captivating hybrid that leaves audiences both amazed and engaged.</motion.p>
                    </div>
                </div>
            )}
            <button
                className={`btn btn-gradient gradient2 ${activeSection === 'videography-photograpgy' ? 'active' : ''}`}
                onClick={() => handleSectionChange('videography-photograpgy')}
                disabled={isLargeScreen && activeSection === 'videography-photograpgy'}
            >
                VIDEOGRAPHY & PHOTOGRAPHY
            </button>
            {activeSection === 'videography-photograpgy' && (
                <div className="details-container details-container-mobile">
                    <div className="left">
                        <motion.img
                            initial={{
                                        opacity: 0,
                                        y: -100
                                    }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0
                                    }}
                                    viewport={{ once: false, amount: 0.5 }}
                                    transition={{ 
                                        duration: 1,
                                        ease: "easeOut",
                                        delay: 0.2
                                    }}
                            src={service6} alt="" />
                    </div>
                        <div className="right">
                    <motion.p
                        initial={{
                                        opacity: 0,
                                        y: 50
                                    }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0
                                    }}
                                    viewport={{ once: false, amount: 0.5 }}
                                    transition={{ 
                                        duration: 1,
                                        ease: "easeOut",
                                        delay: 0.2
                                    }}
                    >Our event video and photography services capture every important moment with precision and style. Using a blend of candid and posed shots, we document events in a way that preserves their energy and emotion. Perfect for corporate events, social gatherings, and special occasions, we deliver high-quality, professional footage and photos that showcase the true spirit of each event.</motion.p>
                    </div>
                </div>
            )}
            <button
                className={`btn btn-gradient gradient2 ${activeSection === 'post-production' ? 'active' : ''}`}
                onClick={() => handleSectionChange('post-production')}
                disabled={isLargeScreen && activeSection === 'post-production'}
            >
                POST PRODUCTION
            </button>
            {activeSection === 'post-production' && (
                <div className="details-container details-container-mobile">
                    <div className="left">
                        <motion.img
                            initial={{
                                opacity: 0,
                                y: -100
                            }}
                            whileInView={{
                                opacity: 1,
                                y: 0
                            }}
                            viewport={{ once: false, amount: 0.5 }}
                            transition={{ 
                                duration: 1,
                                ease: "easeOut",
                                delay: 0.2
                            }}
                            src={service7} alt="" />
                    </div>
                        <div className="right">
                    <motion.p
                        initial={{
                                        opacity: 0,
                                        y: 50
                                    }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0
                                    }}
                                    viewport={{ once: false, amount: 0.5 }}
                                    transition={{ 
                                        duration: 1,
                                        ease: "easeOut",
                                        delay: 0.2
                                    }}
                    >In post-production, we take your footage to its full potential. From expert editing and color grading to seamless transitions and special effects, our post-production team crafts a polished final product that captivates viewers. This crucial stage transforms raw footage into a cinematic experience, ensuring every frame, sound, and visual detail contributes to an impactful story that resonates with your audience.</motion.p>
                    </div>
                </div>
            )}
            </div>

            <div className="services-details">
                {/* Conditionally render the details section based on isDetailsOpen */}
                {isDetailsOpen && isLargeScreen && (
                <div>
                    {activeSection === '2d-animation' && (
                        <div className="details-container">
                            <div className="left">
                                <motion.img 
                                    initial={{
                                        opacity: 0,
                                        y: -100
                                    }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0
                                    }}
                                    viewport={{ once: false, amount: 0.5 }}
                                    transition={{ 
                                        duration: 1,
                                        ease: "easeOut",
                                        delay: 0.2
                                    }}
                                    src={service1} alt="" />
                            </div>
                            <div className="right">
                                <motion.p
                                    initial={{
                                        opacity: 0,
                                        y: 50
                                    }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0
                                    }}
                                    viewport={{ once: false, amount: 0.5 }}
                                    transition={{ 
                                        duration: 1,
                                        ease: "easeOut",
                                        delay: 0.2
                                    }}
                                >Our 2D animation service is designed to elevate brand storytelling with a unique, hand-crafted touch. From character animations to kinetic typography, we create fluid, colorful visuals that engage and inform. Every element is meticulously animated to capture attention and convey your message with creativity and clarity, making 2D animation ideal for explainers, educational videos, and promotional content.</motion.p>
                            </div>
                        </div>
                    )}

                    {activeSection === '3d-animation' && (
                        <div className="details-container">
                            <div className="left">
                                <motion.img
                                    initial={{
                                        opacity: 0,
                                        y: -100
                                    }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0
                                    }}
                                    viewport={{ once: false, amount: 0.5 }}
                                    transition={{ 
                                        duration: 1,
                                        ease: "easeOut",
                                        delay: 0.2
                                    }}
                                    src={service2} alt="" />
                            </div>
                            <div className="right">
                                <motion.p
                                    initial={{
                                        opacity: 0,
                                        y: 50
                                    }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0
                                    }}
                                    viewport={{ once: false, amount: 0.5 }}
                                    transition={{ 
                                        duration: 1,
                                        ease: "easeOut",
                                        delay: 0.2
                                    }}
                                >3D animation allows you to showcase products, ideas, and worlds in lifelike, three-dimensional detail. Perfect for product visualizations, interactive simulations, and cinematic experiences, our 3D animation work combines technical expertise with artistic skill. By blending realistic textures, lighting, and motion, we create immersive animations that leave a lasting impression and bring your vision into a tangible, dynamic format.</motion.p>
                            </div>
                        </div>
                    )}

                    {activeSection === 'cgi' && (
                        <div className="details-container">
                            <div className="left">
                                <motion.img
                                    initial={{
                                        opacity: 0,
                                        y: -100
                                    }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0
                                    }}
                                    viewport={{ once: false, amount: 0.5 }}
                                    transition={{ 
                                        duration: 1,
                                        ease: "easeOut",
                                        delay: 0.2
                                    }}
                                    src={service3} alt="" />
                            </div>
                                <div className="right">
                            <motion.p
                                initial={{
                                        opacity: 0,
                                        y: 50
                                    }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0
                                    }}
                                    viewport={{ once: false, amount: 0.5 }}
                                    transition={{ 
                                        duration: 1,
                                        ease: "easeOut",
                                        delay: 0.2
                                    }}
                                >CGI(Computer-Generated Imagery) transforms the ordinary into the extraordinary, bringing fantasy elements and intricate visual effects into reality. Whether it’s creating a realistic product rendering or building a breathtaking virtual landscape, our CGI team works with precision and artistry to deliver high-quality visuals. Perfect for films, advertisements, and creative projects, CGI pushes the boundaries of what’s possible on screen, making your project unforgettable.</motion.p>
                            </div>
                        </div>
                    )}

                    {activeSection === 'live-action' && (
                        <div className="details-container">
                            <div className="left">
                                <motion.img
                                    initial={{
                                        opacity: 0,
                                        y: -100
                                    }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0
                                    }}
                                    viewport={{ once: false, amount: 0.5 }}
                                    transition={{ 
                                        duration: 1,
                                        ease: "easeOut",
                                        delay: 0.2
                                    }}
                                    src={service4} alt="" />
                            </div>
                                <div className="right">
                            <motion.p
                                initial={{
                                        opacity: 0,
                                        y: 50
                                    }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0
                                    }}
                                    viewport={{ once: false, amount: 0.5 }}
                                    transition={{ 
                                        duration: 1,
                                        ease: "easeOut",
                                        delay: 0.2
                                    }}
                                >Our live action production captures real-life moments with cinematic flair. From corporate events to lifestyle videos, we use professional-grade equipment and artistic direction to highlight the authenticity of your story. Every shot, angle, and scene is carefully curated to enhance storytelling, creating a connection with viewers that feels both immediate and genuine.</motion.p>
                            </div>
                        </div>
                    )}

                    {activeSection === 'visual-production' && (
                        <div className="details-container">
                            <div className="left">
                                <motion.img
                                    initial={{
                                        opacity: 0,
                                        y: -100
                                    }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0
                                    }}
                                    viewport={{ once: false, amount: 0.5 }}
                                    transition={{ 
                                        duration: 1,
                                        ease: "easeOut",
                                        delay: 0.2
                                    }}
                                    src={service5} alt="" />
                            </div>
                                <div className="right">
                            <motion.p
                                initial={{
                                        opacity: 0,
                                        y: 50
                                    }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0
                                    }}
                                    viewport={{ once: false, amount: 0.5 }}
                                    transition={{ 
                                        duration: 1,
                                        ease: "easeOut",
                                        delay: 0.2
                                    }}
                                >Visual Production in a 3D animated environment places real people or characters within a digitally crafted world, blending the boundaries between reality and fantasy. This style is ideal for creating immersive, otherworldly experiences—perfect for brand activations, imaginative storytelling, or futuristic settings. Through expert integration of live elements in animated spaces, we build a captivating hybrid that leaves audiences both amazed and engaged.</motion.p>
                            </div>
                        </div>
                    )}

                    {activeSection === 'videography-photograpgy' && (
                        <div className="details-container">
                            <div className="left">
                                <motion.img
                                    initial={{
                                        opacity: 0,
                                        y: -100
                                    }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0
                                    }}
                                    viewport={{ once: false, amount: 0.5 }}
                                    transition={{ 
                                        duration: 1,
                                        ease: "easeOut",
                                        delay: 0.2
                                    }}
                                    src={service6} alt="" />
                            </div>
                                <div className="right">
                            <motion.p
                                initial={{
                                        opacity: 0,
                                        y: 50
                                    }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0
                                    }}
                                    viewport={{ once: false, amount: 0.5 }}
                                    transition={{ 
                                        duration: 1,
                                        ease: "easeOut",
                                        delay: 0.2
                                    }}
                                >Our event video and photography services capture every important moment with precision and style. Using a blend of candid and posed shots, we document events in a way that preserves their energy and emotion. Perfect for corporate events, social gatherings, and special occasions, we deliver high-quality, professional footage and photos that showcase the true spirit of each event.</motion.p>
                            </div>
                        </div>
                    )}

                    {activeSection === 'post-production' && (
                        <div className="details-container">
                            <div className="left">
                                <motion.img
                                    initial={{
                                        opacity: 0,
                                        y: -100
                                    }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0
                                    }}
                                    viewport={{ once: false, amount: 0.5 }}
                                    transition={{ 
                                        duration: 1,
                                        ease: "easeOut",
                                        delay: 0.2
                                    }}
                                    src={service7} alt="" />
                            </div>
                                <div className="right">
                            <motion.p
                                initial={{
                                        opacity: 0,
                                        y: 50
                                    }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0
                                    }}
                                    viewport={{ once: false, amount: 0.5 }}
                                    transition={{ 
                                        duration: 1,
                                        ease: "easeOut",
                                        delay: 0.2
                                    }}
                                >In post-production, we take your footage to its full potential. From expert editing and color grading to seamless transitions and special effects, our post-production team crafts a polished final product that captivates viewers. This crucial stage transforms raw footage into a cinematic experience, ensuring every frame, sound, and visual detail contributes to an impactful story that resonates with your audience.</motion.p>
                            </div>
                        </div>
                    )}
                </div>
                )}
            </div>
        </div>
    </section>
  );
}