import Helmet from "../Components/Helmet/Helmet";
import AboutUs from "../Components/AboutUs/AboutUs"
import Stages from "../Components/Stages/Stages";
import Professionals from "../Components/Professionals/Professionals";
import Client from "../Components/Clients/Clients";
import Testimonials from "../Components/Testimonials/Testimonials";
import Section2 from "../Components/Section2/Section2";
import Hero from "../Components/Hero/Hero";
import { Gallary } from "../Components/Gallery/Gallary";
import Services from "../Components/Services/Services";
// import { useEffect } from "react";

export default function Home() {
    // useEffect(() => {
    //     handlePageNotFound()
    // }, [handlePageNotFound])
    return (
        <section>
            <Helmet title="Langing Page">
                <Hero/>
                <AboutUs/>
                <Services/>
                <Section2/>
                <Stages/>
                <Client/>
                <Professionals/>
                <Gallary/>
                <Testimonials/>
            </Helmet>
        </section>
    )
}