import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import { AnimatePresence, motion } from 'framer-motion'
import "./Gallary.css"
import LazyLoad from "react-lazyload"

import play from "../../Assets/Common/play-button.svg"
import closeButton from "../../Assets/Common/close-button.svg"

import img1 from '../../Assets/Gallery/1.webp';
import img2 from '../../Assets/Gallery/2.webp';
import img3 from '../../Assets/Gallery/3.webp';
import img4 from '../../Assets/Gallery/4.webp';
import img5 from '../../Assets/Gallery/5.webp';
import img6 from '../../Assets/Gallery/6.webp';
import img7 from '../../Assets/Gallery//talabat.webp';
import img8 from '../../Assets/Gallery/snickers.webp';
import img9 from '../../Assets/Gallery/kellogg.webp';
import img10 from '../../Assets/Gallery/frico.webp';

const videoLinks = {
  video_1: 'https://youtu.be/AT8XT5hX4gs',
  video_2: 'https://youtu.be/cYro99Prxuo',
  video_3: 'https://youtu.be/uZra2LUZ5HI',
  video_4: 'https://youtu.be/lkY7o919Swc',
  video_5: 'https://youtu.be/bAY7fGzsNis',
  video_6: 'https://youtu.be/fA1We1TwihY',
  video_7: "https://youtu.be/xgp1nKSLqLA",
  video_8: "https://youtu.be/FC4ltCe_hB4",
  video_9: "https://youtu.be/lSJHE5-WnN4",
  video_10: "https://youtu.be/3afF5apn07A"
};

const gallaryVariants = {
  initial: { y: 100, opacity: 0 },
  animate: { y: 0, opacity: 1, transition: { duration: 1, staggerChildren: 0.1 } },
  exit: { y: -200, opacity: 0, transition: { duration: .5 } },
};

export const Gallary = () => {
  const [playingVideo, setPlayingVideo] = useState(null);

  const handleVideoClick = (videoUrl) => {
      setPlayingVideo(videoUrl);
    };

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
  const handleResize = () => setScreenWidth(window.innerWidth);

  // Set up event listener
  window.addEventListener("resize", handleResize);

  // Cleanup listener on unmount
  return () => window.removeEventListener("resize", handleResize);
  }, []);

    // const [playingImage, setPlayingImage] = useState(null);

    // const handleImageClick = (imageUrl) => {
    //     setPlayingImage(imageUrl);
    //   };

    // console.log(playingImage)
    // console.log(playingVideo)

    // const [activeSection, setActiveSection] = useState('video-production');

    // const handleSectionChange = (section) => {
    //   setActiveSection(section);
    // }

  return (
    <section id="portfolio" className="portfolio">
      <h1 className="commonHeading">EVENT <span className="color">VIDEO PRODUCTION</span> SUPERHEROES!</h1>
      <p className="commonPara container">Hence it’s mandatory to understand why your start-up business requires an animated explainer video. 
        The explainer video should catch the viewer’s eye whenever they land on your site and consequently, 
        it should be put on the home page. A brand-new animated explainer video for a company is exciting!</p>
      <p className="commonPara container">Video is paramount in today’s marketing world, and this is true from small business to large enterprise. 
        And in a world where video marketing is essential, explainer videos are the best video investment. 
        They’re the perfect foundation for a video campaign.</p>
      <AnimatePresence mode="wait">
      {/* <motion.div 
          initial="initial"
          whileInView="animate"
          transition={{
              duration: 1,
              ease: "easeOut",
              delay: 0.2
          }}
          exit="exit"
          viewport={{ once: false, amount: 0.5 }}
          variants={gallaryVariants}
          className='image-grid'>

        </motion.div> */}
        <motion.div 
          initial="initial"
          whileInView="animate"
          transition={{
              duration: 1,
              ease: "easeOut",
              delay: 0.2
          }}
          exit="exit"
          viewport={{ once: false, amount: 0.5 }}
          variants={gallaryVariants}
          className='image-grid'>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_1)}>
              <img src={img1} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">EVENT COVERAGE</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">BDP</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_2)}>
              <img src={img2} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">EVENT COVERAGE</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">STARBUCKS</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_3)}>
              <img src={img3} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">EVENT COVERAGE</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">LAVAZZA</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_4)}>
              <img src={img4} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">EVENT COVERAGE</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">DUBAI CHAMBERS</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_5)}>
              <img src={img5} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">EVENT COVERAGE</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">PHILIP MORRIS INTERNATIONAL</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_6)}>
              <img src={img6} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">EVENT COVERAGE</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">BISSEL</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_7)}>
              <img src={img7} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">TESTIMONIALS VIDEO</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">TALABAT</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_8)}>
              <img src={img8} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">EVENT COVERAGE</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">SNICKERS</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_9)}>
              <img src={img9} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">EVENT COVERAGE</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">KELLOGG'S</p>
                  </div>
              </div>
          </div>
          <div className={screenWidth < 550 ? "image-grid-item" : "image-grid-item-none"} onClick={() => handleVideoClick(videoLinks.video_10)}>
              <img src={img10} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">EVENT COVERAGE</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">FRICO</p>
                  </div>
              </div>
          </div>
        </motion.div>
        {/* <motion.div 
          initial="initial"
          whileInView="animate"
          transition={{
              duration: 1,
              ease: "easeOut",
              delay: 0.2
          }}
          exit="exit"
          viewport={{ once: false, amount: 0.5 }}
          variants={gallaryVariants}
          className='image-grid'>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_7)}>
              <img src={img7} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">TESTIMONIALS VIDEO</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">TALABAT</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_8)}>
              <img src={img8} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">EVENT COVERAGE</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">SNICKERS</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_9)}>
              <img src={img9} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">EVENT COVERAGE</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">KELLOGG'S</p>
                  </div>
              </div>
          </div>
          <div className={screenWidth < 550 ? "image-grid-item" : "image-grid-item-none"} onClick={() => handleVideoClick(videoLinks.video_9)}>
              <img src={img10} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">EVENT COVERAGE</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">FRICO</p>
                  </div>
              </div>
          </div>
        </motion.div> */}
      </AnimatePresence>
    
    {playingVideo && (
      <div className="video-overlay">
        <LazyLoad offset={100}>
          <img className="close-button1" src={closeButton} alt="" onClick={() => setPlayingVideo(null)}/>
          <div className="video-player">
            <ReactPlayer className="player" url={playingVideo} playing={true} controls width="100%" height="100%" />
          </div>
        </LazyLoad>
      </div>
      )}
  </section>
  )
}

// {/* <button className="close-button1" onClick={() => setPlayingVideo(null)}>Close</button> */}