import { useEffect } from "react"
import notFound from "../../Assets/Common/pagenotfound.svg"

import "./PageNotFound.css"

export default function PageNotFound() {
    return(
        <section id="pagenotfound" className="pagenotfound container1">
            <div className="notfound">
                <img src={notFound} alt=""/>
                <h1>This page doesn't seem to exist.</h1>
            </div>
        </section>
    )
}